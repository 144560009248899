















































































import Vue from 'vue';
import PListView from '@core/components/p-list-view/p-list-view.vue';
import GetMembershipDocumentsRequestViewModel from '@/src/services/viewModel/request/Memberships/GetMembershipDocumentsRequestViewModel';

type FilterType = Parameters<typeof Vue.$service.api.memberships.getListOfMyDocuments>[0];
export default Vue.extend({
  auth: true,
  hasBackButton: true,
  data: () => ({
    filter: {} as FilterType,
    businesses: {},
    invoices: {},
    requestError: undefined,
  }),
  computed: {
    invoiceColumns(): any {
      return [
        { value: this.$t('page.memberData.select.name'), field: 'subject' },
        { value: this.$t('page.memberData.select.date'), field: 'invoiceDate', format: (value: string) => this.$format.localDate(value) },
        { value: this.$t('page.memberData.select.amount'), field: 'amountOutstanding', format: (value: number) => this.$format.currency(value) },
      ];
    },
    documentColumns(): any {
      return [
        { value: this.$t('page.memberData.table.documentName'), field: 'subject' },
        { value: this.$t('page.memberData.table.documentDate'), field: 'createdOn', format: (value: string) => this.$format.localDate(value) },
        { value: this.$t('page.memberData.table.documentType'), field: 'fileExtension' },
      ];
    },
  },
  watch: {
    filter: {
      deep: true,
      handler() {
        const businessesList = this.$refs.businessesList as InstanceType<typeof PListView>;
        this.$debounce(() => {
          businessesList.populateList();
        }, 500, this)();
      },
    },
  },
  mounted() {
    this.filter.dateFrom = this.$date(`${this.$date.now().year()}-1-1`).format('YYYY-MM-DD');
  },
  methods: {
    async downloadDocument(downloadUrl: {value:string}) {
      try {
        const url = downloadUrl.value;
        await this.$service.api.memberships.downloadByUrl(url);
      } catch (error: any) {
        this.requestError = error;
      }
    },
    // eslint-disable-next-line no-unused-vars
    async populateInvoiceData(params: {query: any}) {
      try {
        const response = await this.$service.api.memberships.getMyOpenInvoices();
        return response;
      } catch (error: any) {
        this.requestError = error;
      }
      return {} as any;
    },
    async populateDocumentData(params: {query: any}) {
      try {
        const query = new GetMembershipDocumentsRequestViewModel().fromModel({ ...params.query, ...this.filter });
        const response = await this.$service.api.memberships.getListOfMyDocuments(query);
        return response;
      } catch (error: any) {
        this.requestError = error;
      }
      return {} as any;
    },
  },
});
